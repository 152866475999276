import { createGraphiQLFetcher } from '@graphiql/toolkit';
import GraphiQL from 'graphiql';
import 'graphiql/graphiql.css';
import html2canvas from 'html2canvas';
import React from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import Comparison, { ComparisonUser } from './components/comparison';
import MinutesLineChart from './components/minutes_line_chart';
import ExercisesPieChart from './components/pie_chart';
import RPEBarChart from './components/rpe_bar_chart';
import Sonner, { NotificationType } from './components/sonner';

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('[data-control]').forEach(function (item) {
    switch ((item as HTMLElement).dataset.control) {
      case 'dependent-select': {
        const options = JSON.parse((item as HTMLElement).dataset.options!!);
        const parent = item.querySelector('[data-target="parent"]') as HTMLSelectElement;

        parent.addEventListener('change', function (event) {
          const children = item.querySelector('[data-target="child"]') as HTMLSelectElement;

          children.innerHTML = options[parent.value].map((option) => `<option value=${option}>${option}</option>`);
        });
        break;
      }
      case 'copy-to-clipboard': {
        item.addEventListener('click', () => {
          navigator.clipboard.writeText((item.querySelector('input') as HTMLInputElement).value);
          const react = ReactDOM.createRoot(document.querySelector('#root')!!);
          react.render(<Sonner message="Copied to clipboard!" notificationType="info" />);
        });

        break;
      }
      case 'confirm': {
        item.addEventListener('click', function (event) {
          var confirmation = confirm((item as HTMLElement).dataset.message);

          if (!confirmation) {
            event.preventDefault();
          }
        });

        break;
      }
      case 'form': {
        (item as HTMLFormElement).addEventListener('submit', () => {
          (item.querySelector('input[type="submit"]') as HTMLInputElement).disabled = true;
        });

        break;
      }
      case 'show-options': {
        item.addEventListener('click', () => {
          item.querySelector('[data-target="show-options"]')?.classList.toggle('hidden');
        });

        break;
      }
      case 'slider': {
        item.addEventListener('input', (event) => {
          const progress =
            (Number((event.target as HTMLInputElement).value) / Number((item as HTMLInputElement).max)) * 100;
          (item as HTMLInputElement).style.background =
            `linear-gradient(to right, hsl(var(--primary)) ${progress}%, hsl(var(--secondary)) ${progress}%)`;
        });

        break;
      }
      case 'sonner': {
        const react = ReactDOM.createRoot(item);
        react.render(
          <Sonner
            message={(item as HTMLElement).dataset.message!!}
            notificationType={(item as HTMLElement).dataset.notificationType as NotificationType}
          />,
        );

        break;
      }
      case 'comparison': {
        const mySummary = JSON.parse((item as HTMLElement).dataset.mySummary!!) as ComparisonUser;
        const react = ReactDOM.createRoot(document.querySelector('#root')!!);

        item.querySelectorAll('[data-open-comparison="true"]').forEach((summary) => {
          const userSummary = JSON.parse((summary as HTMLElement).dataset.userSummary!!) as ComparisonUser;

          summary.addEventListener('click', () => {
            react.render(<Comparison key={Math.random()} mySummary={mySummary} userSummary={userSummary} />);
          });
        });

        break;
      }
      case 'download-image': {
        html2canvas(document.querySelector('.card')!!).then((canvas) => {
          const data: string = canvas.toDataURL('image/png;base64');
          const downloadLink: HTMLAnchorElement = item.querySelector('#download')!!;

          downloadLink.download = 'filename';
          downloadLink.href = data;
        });
        break;
      }
      case 'rpe-bar-chart': {
        const react = ReactDOM.createRoot(item);
        react.render(
          <RPEBarChart
            data={JSON.parse((item as HTMLElement).dataset.rpeData!!)}
            title={(item as HTMLElement).dataset.title!!}
            total={(item as HTMLElement).dataset.total!!}
            subtitle={(item as HTMLElement).dataset.subtitle!!}
            color={(item as HTMLElement).dataset.color!!}
          />,
        );
        break;
      }
      case 'minutes-line-chart': {
        const react = ReactDOM.createRoot(item);
        react.render(
          <MinutesLineChart
            data={JSON.parse((item as HTMLElement).dataset.minutes!!)}
            title={(item as HTMLElement).dataset.title!!}
            total={(item as HTMLElement).dataset.total!!}
            subtitle={(item as HTMLElement).dataset.subtitle!!}
          />,
        );
        break;
      }
      case 'pie-chart': {
        const react = ReactDOM.createRoot(item);
        react.render(
          <ExercisesPieChart
            data={JSON.parse((item as HTMLElement).dataset.pie!!)}
            title={(item as HTMLElement).dataset.title!!}
            subtitle={(item as HTMLElement).dataset.subtitle!!}
          />,
        );
        break;
      }
      case '': {
        break;
      }
      default:
        console.log(`Sorry, we are out of ${(item as HTMLElement).dataset.control}.`);
    }
  });

  document.querySelectorAll('[data-action]').forEach(function (item) {
    switch ((item as HTMLElement).dataset.action) {
      case 'create-new-challenge': {
        const exercises = item.querySelectorAll('[data-object="exercise"]') as NodeListOf<HTMLElement>;

        exercises.forEach((exercise) => {
          exercise.addEventListener('click', function () {
            updateExercise(this);
          });
        });

        break;
      }
      case 'add-new-activity': {
        const exercises = item.querySelectorAll('[data-object="exercise"]') as NodeListOf<HTMLFormElement>;
        // I am using this from the create rule page to select the exercise
        const activityInput = document.querySelector('input[name="activity[exercise_id]"]:checked');
        const formsChallengeInput = document.querySelector(
          'input[name="forms_challenge_rule_create[exercise_id]"]:checked',
        );

        const selectedInput = activityInput || formsChallengeInput;

        selectExercise(
          Array.from(exercises).find(
            (element) => element.dataset.exerciseId === (selectedInput as HTMLInputElement).value,
          )!!,
        );

        exercises.forEach((exercise) => {
          exercise.addEventListener('click', function () {
            resetImages(exercises);
            selectExercise(this);
          });
        });

        break;
      }
      default:
        console.log(`Sorry, we are out of ${(item as HTMLElement).dataset.control}.`);
    }
  });

  const graphiqlRoot = document.getElementById('graphiql');

  if (graphiqlRoot != null) {
    const fetcher = createGraphiQLFetcher({
      url: 'http://localhost:3000/graphql',
      headers: { Authorization: graphiqlRoot.dataset.token!! },
    });

    const root = createRoot(graphiqlRoot);
    root.render(<GraphiQL fetcher={fetcher} />);
  }
});

const resetImages = (exercises: NodeListOf<HTMLElement>) => {
  exercises.forEach((exercise) => {
    exercise.querySelector('[data-image="selected"]')?.classList.add('hidden');
    exercise.querySelector('[data-image="default"]')?.classList.remove('hidden');
  });
};

const selectExercise = (element: HTMLElement) => {
  const exerciseRules = document.querySelector('[data-object="exercise-rules"]') as HTMLElement;
  element.querySelector('[data-image="selected"]')?.classList.toggle('hidden');
  element.querySelector('[data-image="default"]')?.classList.toggle('hidden');
  document.getElementById('activity_amount')?.focus();
  exerciseRules.innerHTML = element.dataset.rules ?? '';
};

const updateExercise = (element: HTMLElement) => {
  element.querySelector('[data-image="selected"]')?.classList.toggle('hidden');
  element.querySelector('[data-image="default"]')?.classList.toggle('hidden');
};
